import React, { useCallback, useEffect, useState } from 'react';

import { checkRights } from '../api/api-rights';
import LoadingPage from '../components/UI/LoadingPage';
import { ApiStatusCategories } from '../enum/ApiStatusCategories';
import useHttp from '../hooks/useHttp';

const AuthContext = React.createContext({
    isLoggedIn: false,
    checkLogIn: () => null,
});

const LOGON_SERVICE_URL = process.env.REACT_APP_LOGON_SERVICE_URL;
const SSO_APP_NAME = process.env.REACT_APP_SSO_APP_NAME;

export const AuthContextProvider = (props) => {
    const { sendRequest, status, data, error } = useHttp(checkRights, true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { children } = props;
    const sendCheckRequest = useCallback(() => {
        sendRequest();
    }, [sendRequest]);

    /**
     * Überprüft bei der initialisierung ob der Benutzer eingeloggt ist.
     */
    useEffect(() => {
        sendCheckRequest();
    }, [sendCheckRequest]);

    /**
     * Setzt den aktuellen Status (eingeloggt, nicht eingeloggt) vom Benutzer.
     * Leitet den Benutzer ggf. zur LogIn-Seite weiter, sollte dieser nicht eingeloggt sein.
     */
    useEffect(() => {
        if (status === ApiStatusCategories.COMPLETED) {
            if (error === null) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                window.location.href = decodeURI(
                    LOGON_SERVICE_URL +
                        '?app=' +
                        SSO_APP_NAME +
                        '&reason=timeout&redir=' +
                        window.location.href
                );
            }
        }
        if (status === ApiStatusCategories.FAILED) {
            setIsLoggedIn(false);
            window.location.href = decodeURI(
                LOGON_SERVICE_URL +
                    '?app=' +
                    SSO_APP_NAME +
                    '&reason=timeout&redir=' +
                    window.location.href
            );
        }
    }, [data, error, status, setIsLoggedIn]);

    const contextValue = {
        isLoggedIn: isLoggedIn,
        checkLogIn: sendCheckRequest,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {isLoggedIn && children}
            {!isLoggedIn && status === ApiStatusCategories.PENDING && (
                <LoadingPage />
            )}
        </AuthContext.Provider>
    );
};

export default AuthContext;
