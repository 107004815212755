import { useEffect, useState } from 'react';

const ENV_APP_NAME = process.env.REACT_APP_ENV_APP_NAME;

const useStickyState = (defaultValue, key, sticky = true, alias = null) => {
    const [value, setValue] = useState(() => {
        if (sticky) {
            const stickyValue = window.localStorage.getItem(
                alias === null
                    ? `${ENV_APP_NAME}_${key}`
                    : `${ENV_APP_NAME}_${alias}${key}`
            );
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        }
        return defaultValue;
    });
    useEffect(() => {
        if (sticky) {
            window.localStorage.setItem(
                alias === null
                    ? `${ENV_APP_NAME}_${key}`
                    : `${ENV_APP_NAME}_${alias}${key}`,
                JSON.stringify(value)
            );
        }
    }, [alias, key, sticky, value]);
    return [value, setValue];
};

export default useStickyState;
