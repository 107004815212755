import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppContext from '../../context/app-context';
import ObjectContext, {
    ObjectContextProvider,
} from '../../context/object-context';
import UserContext from '../../context/user-context';
import ViewContext from '../../context/view-context';

const ExtendedRoutes = (props) => {
    const { routes } = props;
    const objectCtx = useContext(ObjectContext);
    const { hasObjectPrivilege } = objectCtx;
    const groupCtx = useContext(ViewContext);
    const { hasGroupPrivilege, hasDomainPrivilege } = groupCtx;
    const userCtx = useContext(UserContext);
    const { isAdmin } = userCtx;
    const appCtx = useContext(AppContext);
    const { isChild } = appCtx;

    return (
        <Routes>
            {routes.map(
                ({
                    path,
                    Component,
                    needsGroupPrivilege,
                    needsDomainPrivilege,
                    needsAdminPrivilege,
                    needsObjectPrivilege,
                    initialObjectContext,
                    slaveAccessible,
                }) => {
                    if (!isChild || (isChild && slaveAccessible)) {
                        if (
                            (needsGroupPrivilege === undefined ||
                                hasGroupPrivilege(needsGroupPrivilege)) &&
                            (needsDomainPrivilege === undefined ||
                                hasDomainPrivilege(needsDomainPrivilege)) &&
                            (needsAdminPrivilege === undefined ||
                                needsAdminPrivilege === isAdmin)
                        ) {
                            if (needsObjectPrivilege === undefined) {
                                return initialObjectContext ? (
                                    <ObjectContextProvider>
                                        {Component}
                                    </ObjectContextProvider>
                                ) : (
                                    <Route
                                        key={path}
                                        exact
                                        path={path}
                                        element={Component}
                                    />
                                );
                            } else {
                                return (
                                    <Route
                                        key={path}
                                        exact
                                        path={path}
                                        element={
                                            initialObjectContext ? (
                                                <ObjectContextProvider
                                                    requiredRights={
                                                        needsObjectPrivilege
                                                    }>
                                                    {Component}
                                                </ObjectContextProvider>
                                            ) : hasObjectPrivilege(
                                                  needsObjectPrivilege
                                              ) ? (
                                                Component
                                            ) : (
                                                <Navigate replace to="/" />
                                            )
                                        }
                                    />
                                );
                            }
                        }
                    }
                }
            )}
        </Routes>
    );
};

ExtendedRoutes.propTypes = {
    routes: PropTypes.array.isRequired,
};

export default ExtendedRoutes;
