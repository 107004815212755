import React, { useCallback, useContext, useEffect, useState } from 'react';

import { usePageVisibility } from 'react-page-visibility';

import config from './../../src/config.json';
import AppContext from './app-context';
import UserContext from './user-context';
import { getObjectReferenceFromObject } from '../api/api-objref';
import { getRights } from '../api/api-rights';
import {
    getDomainStations,
    getGroupStations,
    getStations,
} from '../api/api-station';
import { ApiStatusCategories } from '../enum/ApiStatusCategories';
import useHttp from '../hooks/useHttp';
import useStickyState from '../hooks/useStickyState';

const { refreshStationsDuration } = config;

const ViewContext = React.createContext({
    group: null,
    setGroup: () => null,
    groupPrivileges: { Rights: {} },
    hasGroupPrivilege: () => null,
    hasViewObjectReference: () => null,
    domain: null,
    setDomain: () => null,
    domainPrivileges: { Rights: {} },
    hasDomainPrivilege: () => null,
    stations: [],
    stationsStatus: null,
    stationsChanged: true,
    reloadStations: () => null,
    setStationsChanged: () => null,
    hasViewPrivilege: () => null,
    timestampStations: null,
    statusStations: ApiStatusCategories.PENDING,
});

export const GroupContextProvider = (props) => {
    const userCtx = useContext(UserContext);
    const appCtx = useContext(AppContext);
    const { userId, domainId: userDomainId, isAdmin, domains } = userCtx;
    const { isChild, isOverrideStatus } = appCtx;
    const [domain, setDomain] = useStickyState(
        domains?.length === 1 ? domains[0] : null,
        'domain',
        true
    );
    const [timestampStations, setTimestampStations] = useState(null);
    const [group, setGroup] = useStickyState(null, 'group', true);
    const [domainPrivileges, setDomainPrivileges] = useState({ Rights: {} });
    const [groupPrivileges, setGroupPrivileges] = useState({ Rights: {} });
    const [domainObjectReference, setDomainObjectReference] = useState({});
    const [userObjectReference, setUserObjectReference] = useState({});
    const [stationsChangedDate, setStationsChangedDate] = useState(new Date());
    const [stationsChanged, setStationsChanged] = useState(true);
    const [stations, setStations] = useState([]);
    const isPageVisible = usePageVisibility();
    const { children } = props;
    const {
        sendRequest: sendDomainPrivilegesRequest,
        status: statusDomainPrivilegesRequest,
        data: domainPrivilegesData,
    } = useHttp(getRights);
    const {
        sendRequest: sendGroupPrivilegesRequest,
        status: statusGroupPrivilegesRequest,
        data: groupPrivilegesData,
    } = useHttp(getRights);
    const {
        sendRequest: sendAllStationsRequest,
        status: statusAllStationsRequest,
        data: loadedAllStations,
        timestamp: timestampAllStations,
    } = useHttp(getStations);
    const {
        sendRequest: sendGroupStationsRequest,
        status: statusGroupStationsRequest,
        data: loadedGroupStations,
        timestamp: timestampGroupStations,
    } = useHttp(getGroupStations);
    const {
        sendRequest: sendDomainStationsRequest,
        status: statusDomainStationsRequest,
        data: loadedDomainStations,
        timestamp: timestampDomainStations,
    } = useHttp(getDomainStations);
    const {
        sendRequest: sendGetDomainObjectReferenceFromObjectRequest,
        status: statusSendDomainObjectReferenceFromObjectRequest,
        data: loadedDomainObjectReference,
    } = useHttp(getObjectReferenceFromObject);
    const {
        sendRequest: sendGetUserObjectReferenceFromObjectRequest,
        status: statusSendUserObjectReferenceFromObjectRequest,
        data: loadedUserObjectReference,
    } = useHttp(getObjectReferenceFromObject);

    /**
     * Senden den Request für die Stationen, abhängig von der selektierten Domain und Gruppe, ab.
     * Keine Domain ausgewählt → /stations
     * Domain ausgewählt und keine Gruppe ausgewählt → /stationsofdomain
     * Domain ausgewählt und Gruppe ausgewählt → /stationsofgroups
     * @type {(function(): void)|*}
     */
    const reloadStations = useCallback(() => {
        if (!isChild) {
            if (domain == null) {
                sendAllStationsRequest();
            } else {
                if (group == null) {
                    sendDomainStationsRequest({
                        ID_Domain: domain.ID_Domain,
                    });
                } else {
                    sendGroupStationsRequest({ ID_Group: group.ID_Group });
                }
            }
        }
    }, [
        sendDomainStationsRequest,
        sendGroupStationsRequest,
        sendAllStationsRequest,
        isChild,
        domain,
        group,
    ]);

    /** Sendet den oder die Requests für die Rechte, abhängig von der selektierten Domain und Gruppe, ab.
     * Keine Domain ausgewählt → Rechte-Request auf die Domain des Benutzers.
     * Domain ausgewählt und keine Gruppe ausgewählt → Rechte-Request auf die selektierte Domain.
     * Domain ausgewählt und Gruppe ausgewählt → Rechte-Request auf die selektierte Domain und Rechte-Request auf die selektierte Gruppe
     * @type {(function(): void)|*}
     */
    const reloadPrivileges = useCallback(() => {
        if (domain == null) {
            sendDomainPrivilegesRequest({
                params: {
                    ID_Target: userDomainId,
                    ID_Member: userId,
                },
            });
            setGroupPrivileges({ Rights: {} });
            sendGetDomainObjectReferenceFromObjectRequest({
                objectId: userDomainId,
            });
        } else {
            sendGetDomainObjectReferenceFromObjectRequest({
                objectId: domain.ID_Domain,
            });
            if (group == null) {
                sendDomainPrivilegesRequest({
                    params: {
                        ID_Target: domain.ID_Domain,
                        ID_Member: userId,
                    },
                });
            } else {
                sendDomainPrivilegesRequest({
                    params: {
                        ID_Target: domain.ID_Domain,
                        ID_Member: userId,
                    },
                });
                sendGroupPrivilegesRequest({
                    params: {
                        ID_Target: group.ID_Group,
                        ID_Member: userId,
                    },
                });
            }
        }
        sendGetUserObjectReferenceFromObjectRequest({
            objectId: userId,
        });
    }, [
        sendGetDomainObjectReferenceFromObjectRequest,
        sendGetUserObjectReferenceFromObjectRequest,
        sendDomainPrivilegesRequest,
        sendGroupPrivilegesRequest,
        userDomainId,
        userId,
        domain,
        group,
    ]);

    /**
     * Timer der die Stationen regelmäßig aktualisiert.
     */
    useEffect(() => {
        const interval = setInterval(() => {
            if (isPageVisible) {
                reloadStations();
            }
        }, refreshStationsDuration);
        return () => {
            clearInterval(interval);
            if (
                new Date() - stationsChangedDate >
                    refreshStationsDuration * 1.5 &&
                !isPageVisible
            ) {
                reloadStations();
            }
        };
    }, [isChild, reloadStations, stationsChangedDate, isPageVisible]);

    const overrideStatus = useCallback(
        (stations) => {
            if (isOverrideStatus) {
                return stations.map((station) =>
                    station.DeviceStatus === 2 || station.DeviceStatus === 1
                        ? {
                              ...station,
                              DeviceStatus: 0,
                              Errors: {},
                          }
                        : station.DeviceStatus === 3
                          ? {
                                ...station,
                                Errors: {
                                    ...station.Errors,
                                    Warnings: [],
                                    Unknowns: [],
                                },
                            }
                          : {
                                ...station,
                                Errors: {},
                            }
                );
            }
            return stations;
        },
        [isOverrideStatus]
    );

    /**
     * Wenn der Stations-Request erfolgreich abgeschlossen wurde,
     * werden hier die Stationen gesetzt.
     */
    useEffect(() => {
        if (statusAllStationsRequest === ApiStatusCategories.COMPLETED) {
            setStations(overrideStatus(loadedAllStations?.Stations ?? []));
            setStationsChangedDate(new Date());
            setStationsChanged(false);
            setTimestampStations(timestampAllStations);
        }
        if (statusAllStationsRequest === ApiStatusCategories.FAILED) {
            setStations([]);
            setStationsChanged(false);
            setTimestampStations(null);
        }
    }, [
        statusAllStationsRequest,
        setTimestampStations,
        timestampAllStations,
        setStationsChanged,
        loadedAllStations,
        overrideStatus,
        setStations,
    ]);

    /**
     * Wenn der StationsOfGroups-Request erfolgreich abgeschlossen wurde,
     * werden hier die Stationen gesetzt.
     */
    useEffect(() => {
        if (statusGroupStationsRequest === ApiStatusCategories.COMPLETED) {
            setStations(overrideStatus(loadedGroupStations?.Stations ?? []));
            setStationsChanged(false);
            setTimestampStations(timestampGroupStations);
        }
        if (statusGroupStationsRequest === ApiStatusCategories.FAILED) {
            setStations([]);
            setStationsChanged(false);
            setTimestampStations(null);
        }
    }, [
        statusGroupStationsRequest,
        timestampGroupStations,
        setTimestampStations,
        loadedGroupStations,
        setStationsChanged,
        overrideStatus,
        setStations,
    ]);

    /**
     * Wenn der StationsOfDomain-Request erfolgreich abgeschlossen wurde,
     * werden hier die Stationen gesetzt.
     */
    useEffect(() => {
        if (statusDomainStationsRequest === ApiStatusCategories.COMPLETED) {
            setStations(overrideStatus(loadedDomainStations?.Stations ?? []));
            setStationsChanged(false);
            setTimestampStations(timestampDomainStations);
        }
        if (statusDomainStationsRequest === ApiStatusCategories.FAILED) {
            setStations([]);
            setStationsChanged(false);
            setTimestampStations(null);
        }
    }, [
        statusDomainStationsRequest,
        timestampDomainStations,
        setTimestampStations,
        loadedDomainStations,
        setStationsChanged,
        overrideStatus,
        setStations,
    ]);

    /**
     * Setzt die Objektreferenz von dem Objekt (Domain)
     */
    useEffect(() => {
        if (
            statusSendDomainObjectReferenceFromObjectRequest ===
            ApiStatusCategories.COMPLETED
        ) {
            setDomainObjectReference(loadedDomainObjectReference?.ObjRef ?? {});
        }
    }, [
        setDomainObjectReference,
        loadedDomainObjectReference,
        statusSendDomainObjectReferenceFromObjectRequest,
    ]);

    /**
     * Setzt die Objektreferenz von dem Objekt (Domain)
     */
    useEffect(() => {
        if (
            statusSendUserObjectReferenceFromObjectRequest ===
            ApiStatusCategories.COMPLETED
        ) {
            setUserObjectReference(loadedUserObjectReference?.ObjRef ?? {});
        }
    }, [
        setUserObjectReference,
        loadedUserObjectReference,
        statusSendUserObjectReferenceFromObjectRequest,
    ]);

    /**
     * Überprüft die Rechte, die der Benutzer auf die ausgewählte Domain hat.
     * @type {(...args: any[]) => any}
     */
    const hasDomainPrivilege = useCallback(
        (categories) => {
            if (Array.isArray(categories)) {
                for (let category of categories) {
                    if (
                        isAdmin ||
                        (domainPrivileges['Rights'][category] ?? false)
                    ) {
                        return true;
                    }
                }
            } else {
                return (
                    isAdmin || (domainPrivileges['Rights'][categories] ?? false)
                );
            }
            return false;
        },
        [domainPrivileges, isAdmin]
    );

    /**
     * Überprüft die Rechte, die der Benutzer auf die ausgewählte Gruppe hat.
     * Wenn der Benutzer keine Gruppe ausgewählt hat, werden die Rechte auf
     * die Domain geprüft.
     * @type {(...args: any[]) => any}
     */
    const hasGroupPrivilege = useCallback(
        (categories) => {
            if ((group === null && domain !== null) || domain === null) {
                return hasDomainPrivilege(categories);
            }
            if (Array.isArray(categories)) {
                for (let category of categories) {
                    if (
                        isAdmin ||
                        (groupPrivileges['Rights'][category] ?? false)
                    ) {
                        return true;
                    }
                }
            } else {
                return (
                    isAdmin || (groupPrivileges['Rights'][categories] ?? false)
                );
            }
            return false;
        },
        [groupPrivileges, group, domain, hasDomainPrivilege, isAdmin]
    );

    /**
     * Überprüft ob der Benutzer ein Recht auf die Gruppe oder Domain hat.
     * @type {(...args: any[]) => any}
     */
    const hasViewPrivilege = useCallback(
        (categories) => {
            return (
                hasGroupPrivilege(categories) || hasDomainPrivilege(categories)
            );
        },
        [hasGroupPrivilege, hasDomainPrivilege]
    );

    /**
     * Überprüft ob der Benutzer eine Objektreferenz auf das Domain-Objekt hat.
     * @type {(...args: any[]) => any}
     */
    const hasDomainObjectReference = useCallback(
        (categories) => {
            if (Array.isArray(categories)) {
                for (let category of categories) {
                    if (domainObjectReference?.ObjRef === category) {
                        return true;
                    }
                }
            } else {
                return domainObjectReference?.ObjRef === categories;
            }
            return false;
        },
        [domainObjectReference]
    );

    /**
     * Überprüft ob der Benutzer eine Objektreferenz auf das Domain-Objekt hat.
     * @type {(...args: any[]) => any}
     */
    const hasUserObjectReference = useCallback(
        (categories) => {
            if (Array.isArray(categories)) {
                for (let category of categories) {
                    if (userObjectReference?.ObjRef === category) {
                        return true;
                    }
                }
            } else {
                return userObjectReference?.ObjRef === categories;
            }
            return false;
        },
        [userObjectReference]
    );

    /**
     * Überprüft ob der Benutzer ein Recht auf die Gruppe oder Domain hat.
     * @type {(...args: any[]) => any}
     */
    const hasViewObjectReference = useCallback(
        (categories) => {
            return (
                hasDomainObjectReference(categories) ||
                hasUserObjectReference(categories)
            );
        },
        [hasDomainObjectReference, hasUserObjectReference]
    );

    /**
     * Initialisiert die Daten beim ersten Aufruf
     */
    useEffect(() => {
        reloadPrivileges();
        reloadStations();
    }, [reloadStations, reloadPrivileges, group]);

    /**
     * Setzt die Rechte auf die Domain
     */
    useEffect(() => {
        if (statusDomainPrivilegesRequest === ApiStatusCategories.COMPLETED) {
            setDomainPrivileges(domainPrivilegesData);
        }
    }, [
        statusDomainPrivilegesRequest,
        domainPrivilegesData,
        setDomainPrivileges,
    ]);

    /**
     * Setzt die Rechte auf die Gruppe
     */
    useEffect(() => {
        if (statusGroupPrivilegesRequest === ApiStatusCategories.COMPLETED) {
            setGroupPrivileges(groupPrivilegesData);
        }
    }, [statusGroupPrivilegesRequest, groupPrivilegesData, setGroupPrivileges]);

    /**
     * Gibt den aktuellen Status des Stations-Request zurück. Abhängig von dem Filter.
     * @returns {*}
     */
    const getStatusStations = () => {
        if (domain == null) {
            return statusAllStationsRequest;
        } else {
            if (group == null) {
                return statusDomainStationsRequest;
            } else {
                return statusGroupStationsRequest;
            }
        }
    };

    return (
        <ViewContext.Provider
            value={{
                group,
                setGroup,
                domain,
                setDomain,
                objectReference: domainObjectReference,
                hasViewPrivilege,
                hasGroupPrivilege,
                hasDomainPrivilege,
                hasViewObjectReference,
                stations: stations ?? [],
                stationsStatus:
                    stations.length < 1
                        ? getStatusStations()
                        : ApiStatusCategories.COMPLETED,
                stationsChanged,
                setStationsChanged,
                reloadStations,
                timestampStations: timestampStations,
                statusStations: !domain
                    ? statusAllStationsRequest
                    : !group
                      ? statusDomainStationsRequest
                      : statusGroupStationsRequest,
            }}>
            {children}
        </ViewContext.Provider>
    );
};

export default ViewContext;
